@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// @import "primeng/resources/themes/lara-light-blue/theme.css";

@import 'assets/scss/themes/mytheme/theme.scss';
@import 'primeng/resources/primeng.css';

body {
	overflow-y: scroll
}

.xng-breadcrumb-root {
	@apply rounded-lg bg-gray p-2;
}

.xng-breadcrumb-list {
	@apply opacity-70 text-xs;
}